body {
  margin: 0;
  background-color: #f3f3f3;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn.btn-twitter {
  background-color: #b0bf28 !important;
  font-size: 14px !important;
  color: #fff;
}

.show-contact {
  position: absolute;
  margin-top: -85px;
  text-align: center;
  width: 100%;
  background: #222c4599;
  margin-left: 0;
}

span.price {
  font-size: 2em;
}

.cicleAction {
  float: left;
  border: 3px transparent;
  padding: 20px;
  border-radius: 50%;
  text-align: center;
  margin-right: 15px;
  background: #222b45;
  color: #fff;
}
.form-check .form-check-label {
  padding-left: 25px;
}
h3.consior {
  background: #b0bf27;
  display: table;
  padding: 3px 10px 3px 50px;
  font-weight: 800;
  color: #222b45;
  margin-bottom: 10px;
}
.form-group .form-control, .input-group .form-control, input.form-control {
  padding: 10px 30px 10px 10px !important;
  border-radius: 5px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.form-check .form-check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 20px !important;
  padding-right: 30px;
  line-height: 26px;
  margin-bottom: 0;
  transition: color .3s linear;
}

ol.carousel-indicators li {
  margin-bottom: -50px;
  /* margin-top: 50px; */
}

.talk {
  margin-top: 8px;
  font-weight: 800;
  font-size: 1.4em
}

.griting {
  background: #2129449e;
  color: #fff;
  padding: 30px;
  margin-bottom: 50px;
  font-size: 22px;
  font-weight: 800;
}

@media only screen and (max-width: 600px) {
  .talk {
    font-size: 1em;
  }
  h3.consior {
    background: #b0bf27;
    display: table;
    padding: 3px 10px 3px 10px;
    font-weight: 800;
    color: #222b45;
    margin-bottom: 10px;
}
}
